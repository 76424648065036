<template>
  <ClientOnly>
    <div class="flex min-h-full flex-1 flex-col justify-center mx-2">
      <div class="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          class="mx-auto h-20 w-auto rounded-lg"
          src="~/assets/img/the_tax_ghost_suit.gif"
          alt="Your Company"
        />
        <h2
          class="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900"
        >
          {{ isLogin ? "Sign in to your" : "Create a" }} Tax Ghost account
        </h2>
      </div>

      <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
        <div
          class="bg-white px-6 py-12 shadow-lg border border-gray-200 sm:rounded-lg sm:px-12"
        >
          <form
            class="space-y-4"
            @submit.prevent="isLogin ? handleSignIn() : handleSignUp()"
          >
            <Message v-if="authError" :closable="false" severity="error">
              {{ authError }}
            </Message>
            <Message v-if="verifyMessage" :closable="false" severity="success">
              {{ verifyMessage }}
            </Message>
            <div>
              <label
                for="email"
                class="block text-sm font-medium leading-6 text-gray-900"
              >
                Email address
              </label>
              <div class="mt-2 flex">
                <InputText
                  v-model="formValues.email"
                  data-testid="email-input"
                  class="flex-grow"
                  type="email"
                />
              </div>
            </div>

            <div>
              <label
                for="password"
                class="block text-sm font-medium leading-6 text-gray-900"
              >
                Password
              </label>
              <div class="mt-2 flex">
                <InputText
                  v-model="formValues.password"
                  data-testid="password-input"
                  class="flex-grow"
                  type="password"
                />
              </div>
            </div>

            <div v-if="!isLogin">
              <label
                for="role"
                class="block text-sm font-medium leading-6 text-gray-900"
              >
                Role
              </label>
              <div class="mt-2 flex">
                <Dropdown
                  v-model="formValues.role"
                  data-testid="role-dropdown"
                  :options="roleOptions"
                  option-label="name"
                  placeholder="Select a Role"
                  class="w-full md:w-14rem"
                />
              </div>
            </div>

            <div v-if="isLogin" class="flex items-center justify-between">
              <div class="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  class="h-4 w-4 rounded border-gray-300 text-gray-900 focus:ring-gray-600"
                />
                <label
                  for="remember-me"
                  class="ml-3 block text-sm leading-6 text-gray-900"
                >
                  Remember me
                </label>
              </div>

              <div class="text-sm leading-6">
                <nuxt-link
                  to="/reset-password"
                  class="font-semibold text-gray-600 hover:text-gray-500"
                >
                  Forgot password?
                </nuxt-link>
              </div>
            </div>

            <div class="flex justify-center">
              <Button
                class="flex-grow"
                type="submit"
                :loading="loading"
                :label="isLogin ? 'Sign In' : 'Sign Up'"
              />
            </div>
          </form>

          <!-- <div>
            <div class="relative mt-10">
              <div
                class="absolute inset-0 flex items-center"
                aria-hidden="true"
              >
                <div class="w-full border-t border-gray-200" />
              </div>
              <div
                class="relative flex justify-center text-sm font-medium leading-6"
              >
                <span class="bg-white px-6 text-gray-900"
                  >Or continue with</span
                >
              </div>
            </div>

            <div class="mt-6 grid grid-cols-2 gap-4">
              <a
                href="#"
                class="flex w-full items-center justify-center gap-3 rounded-md bg-[#24292F] px-3 py-1.5 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#24292F]"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 48 48"
                  width="24px"
                  height="24px"
                >
                  <path
                    fill="#FFC107"
                    d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
                  />
                  <path
                    fill="#FF3D00"
                    d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
                  />
                  <path
                    fill="#4CAF50"
                    d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
                  />
                  <path
                    fill="#1976D2"
                    d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
                  />
                </svg>
                <span class="text-sm font-semibold leading-6">Google</span>
              </a>
              <a
                href="#"
                class="flex w-full items-center justify-center gap-3 rounded-md bg-[#1D9BF0] px-3 py-1.5 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#1D9BF0]"
              >
                <svg
                  class="h-5 w-5"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84"
                  />
                </svg>
                <span class="text-sm font-semibold leading-6">Twitter</span>
              </a>
            </div>
          </div> -->
        </div>

        <p class="mt-10 text-center text-sm text-gray-500">
          {{ isLogin ? "Not a member?" : "Have an account?" }}
          <button
            href="#"
            class="font-semibold leading-6 text-gray-900 hover:text-gray-700"
            @click="toggleLogin"
          >
            {{ !isLogin ? "Sign In" : "Create an account" }}
          </button>
        </p>
      </div>
    </div>
  </ClientOnly>
</template>

<script setup lang="ts">
import UserRoleEnum from "~/enums/userRoleEnum";
import { validateUserOnLogin } from "~/util/user-validators";
import { v4 as uuidv4 } from "uuid";

const supabase = useSupabaseClient<Database>();
const user = useSupabaseUser();

const authStore = useAuthStore();
const { currentUser } = storeToRefs(authStore);

const isLogin = ref(true);
const loading = ref(false);
const authError = ref("");
const verifyMessage = ref("");

const formValues = reactive({
  email: "",
  password: "",
  role: { name: "Select a role", value: null },
});

const roleOptions = [
  { name: "CPA", value: UserRoleEnum.CPA },
  { name: "Financial Advisor", value: UserRoleEnum.ADVISOR },
];

const toggleLogin = () => {
  authError.value = "";
  verifyMessage.value = "";
  isLogin.value = !isLogin.value;
};

const validateInputs = () => {
  if (isLogin.value) {
    if (!formValues.email || !formValues.password) {
      return "Email and Password Required!";
    }
  } else {
    if (!formValues.email || !formValues.password || !formValues.role.value) {
      return "Email, Password, and Role Required!";
    }
  }

  return null;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleAuthFlow = async (error: any) => {
  if (error) {
    authError.value = error.message;
    loading.value = false;
    return;
  }

  await authStore.fetchCurrentUser(user.value?.id as string);

  if (!currentUser.value) {
    authError.value = "There was an error signing in. Please try again.";
    return;
  }

  if (currentUser.value?.password_reset_required) {
    navigateTo("/force-password-change");
    return;
  }

  const userIsValid = validateUserOnLogin(currentUser.value as User);
  if (!userIsValid) {
    navigateTo(
      `user-settings/profile-setup/${currentUser.value?.auth_user_id}`
    );
    loading.value = false;
    return;
  }

  loading.value = false;
  navigateTo("/dashboard");
};

const handleSignUp = async () => {
  const inputError = validateInputs();
  if (inputError) {
    authError.value = inputError;
    return;
  }

  loading.value = true;
  const { data: signUpData, error: signUpError } = await supabase.auth.signUp({
    email: formValues.email,
    password: formValues.password,
  });

  if (signUpError || !signUpData?.user?.id) {
    authError.value = "There was an error signing up. Please try again.";
    loading.value = false;
    return;
  }

  const { error } = await supabase.from("users").insert([
    {
      auth_user_id: signUpData?.user?.id,
      email: formValues.email,
      role: formValues.role.value,
      referral_code:
        formValues.role.value === UserRoleEnum.CPA ? uuidv4() : null,
    },
  ]);

  handleAuthFlow(error);
};

const handleSignIn = async () => {
  const inputError = validateInputs();
  if (inputError) {
    authError.value = inputError;
    return;
  }

  loading.value = true;

  const { error } = await supabase.auth.signInWithPassword({
    email: formValues.email,
    password: formValues.password,
  });

  handleAuthFlow(error);
};
</script>
