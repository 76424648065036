import { every } from "lodash-es";
export const validateUserOnLogin = (user: User) => {
  const userIsValid = every(
    [
      user.business_name,
      user.city,
      user.first_name,
      user.last_name,
      user.phone_number,
      user.postal_code,
      user.state,
      user.street_address,
      user.email,
    ],
    (value) => value
  );

  return userIsValid;
};
