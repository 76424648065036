<template>
  <div class="h-screen flex align-middle justify-center">
    <LoginSignUp />
  </div>
</template>

<script setup lang="ts">
import LoginSignUp from "@/components/auth/LoginSignUp.vue";

definePageMeta({
  layout: "unauthenticated",
});

useHead({
  title: "The Tax Ghost - Login",
  meta: [
    {
      name: "description",
      content: "Log in to your Tax Ghost account.",
    },
  ],
});
</script>

<style scoped></style>
